import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [prompt, setPrompt] = useState('');
  const [image, setImage] = useState('');
  const [model, setModel] = useState('PixelArtRedmond');
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTiming, setIsTiming] = useState(false);
  const [sliderValue, setSliderValue] = useState(128); 
  const [dimesions, setDimesionsValue] = useState(128); 

  useEffect(() => {
    let timer;
    if (isTiming) {
      timer = setInterval(() => {
        setElapsedTime((Date.now() - startTime) / 1000);
      }, 100);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isTiming, startTime]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setStartTime(Date.now());
    setElapsedTime(0);
    setIsTiming(true);
    setDimesionsValue(128);
  
    try {
      if (image) {
        URL.revokeObjectURL(image);
        setImage('');
      }
  
      const timestamp = new Date().getTime();
      const imageResponse = await axios.post(`/select_image?t=${timestamp}`, 
        { prompt, model, timestamp }, 
        { responseType: 'blob' }
      );
      const imageBlob = imageResponse.data;
      const imageURL = URL.createObjectURL(imageBlob);
      setImage(imageURL);
      setIsTiming(false);
  
    } catch (error) {
      console.error('There was an error:', error);
      setIsTiming(false);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = image;
    link.download = 'generated_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const handleTransparentDownload = async () => {
    try {
      const formData = new FormData();
      const response = await fetch(image);
      const blob = await response.blob();
      formData.append('image', blob);
      formData.append('pixel_size', dimesions);

      const transparentResponse = await axios.post('/download_transparent', formData, { responseType: 'blob' });
      const transparentBlob = transparentResponse.data;
      const transparentURL = URL.createObjectURL(transparentBlob);

      const link = document.createElement('a');
      link.href = transparentURL;
      link.download = 'transparent_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading transparent image', error);
    }
  };

  const handlePixelate = async () => {
    try {
      const formData = new FormData();
      const response = await fetch(image);
      const blob = await response.blob();
      formData.append('image', blob);
      formData.append('pixel_size', sliderValue);

      const pixelateResponse = await axios.post('/pixelate_image', formData, { responseType: 'blob' });
      const pixelatedImageBlob = pixelateResponse.data;
      const pixelatedImageURL = URL.createObjectURL(pixelatedImageBlob);
      setImage(pixelatedImageURL);
      setDimesionsValue(sliderValue);
    } catch (error) {
      console.error('Error pixelating image', error);
    }
  };

  const cleanupImageURL = useCallback(() => {
    if (image) {
      URL.revokeObjectURL(image);
    }
  }, [image]);

  useEffect(() => {
    return () => {
      cleanupImageURL();
    };
  }, [image, cleanupImageURL]);

  
  
  return (
    <div className="App">
      <header className="App-header">
        <header class="header">
          <h1 class="main-title">PixiGen</h1>
          <p class="subtitle">AI pixel sprite generator</p>
        </header>
        <div className="chat-container">
          <form className="form-container" onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <textarea
                className="input-box"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your prompt here"
              />
              <button className="submit-button" type="submit">{'>'}</button>
              <div className="timer">
                <p>{elapsedTime.toFixed(2)}s</p>
              </div>  
            </div>
          </form>
          <div className="settings">
            <select
              className="model-select"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            >
              <option value="PixelArtRedmond">PixelArtRedmond</option>
              <option value="pixel-art-xl">pixel-art-xl</option>
            </select>
            <div className="slider-container">
              <input
                type="range"
                min="1"
                max="128"
                value={sliderValue}
                className="slider"
                onChange={(e) => setSliderValue(e.target.value)}
              />
              <p className="slider-text">{sliderValue} px</p>
              <button className="pixelate-button" onClick={handlePixelate}>Pixelate</button>
            </div>
          </div>
          <div className="output-box">
            {image && <img className="image-box" src={image} alt="Generated Art" />}
            {image && <button className="download-button" onClick={handleDownload}>download</button>}
            {image && <button className="download-transparent-button" onClick={handleTransparentDownload}>download transparent</button>}
            <p className="dimesions"> {dimesions} x {dimesions}</p>
          </div>
          
          <p className='note_prompt'>*Not what you wanted? Try a different prompt or Model.</p>
        </div>
      </header>
    </div>
  );
}

export default App;
